.hero-area {
    display: flex;
}

.hero-image {
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    background-image: url("/assets/hero_imagecopy.jpg");
    background-size: cover;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-attachment: scroll;
    background-repeat: no-repeat;
    position: fixed;
    z-index: -1;
}

.hero-box {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    min-width: 95vw;
    text-align: left;
    justify-content: center;
}
.hero-box h1 {
    background-color: white;
    font-size: calc(3em + 0.2vw);
    display: inline-block;
    margin-right: 10vw;
    margin-top: 0;
    padding-left: 0;
    box-decoration-break: slice;
    box-decoration-break: clone;
}                                       
@media screen and (max-width:100vh) {
    .hero-image {
        background-position-x: 30%;        
    }
}
@media screen and (max-width:700px) {
    .hero-box h1 {
        font-size: calc(1em + 2.3vw);
        margin-right: 5vw;
        
    }
}
.text-line {
    display: flex;
}
.text-line .spacer {
    flex-grow: 1;
}
@supports (-webkit-overflow-scrolling: touch) {
    .hero-image {
        max-height: 85vh;
    }
    .hero-box {
        min-height: 85vh;
    }
}
