@import url('https://fonts.googleapis.com/css2?family=Open+Sans&display=swap');
.blog {
    font-family: 'Open Sans', sans-serif;
}
.blog-nav {
    background-color: #f5f3f4;
    z-index: 1;
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: fixed;
    right:0;
    left: 0;
    top: 0;
    box-shadow: 0px 2px 10px 4px lightgray;
    padding-left: 1rem;
    padding-right: 1rem;
}
.blog-nav a {
    color: black;
    text-decoration: initial;
}
.blog-nav img {
    max-width: 2rem;
}
.blog .blog-list {
    display: flex;
    flex-direction: column;
    padding-top: 6rem;
    max-width: 700px;
    margin: auto;
}
.blog-list a {
    color: black;
    text-decoration: initial;
}
.blog-list a h1 {
    margin-bottom: 0.2rem;
}
.blog-list a:hover h1 {
    text-decoration: underline;
}
.blogpost-area {
    padding: 1rem;
    border-bottom: 1px solid black;
}
.blogpost-area p {
    padding-right: 0;
    padding-left: 0;
}
@media screen and (min-width:750px) {
    .blogpost-area {
        display: grid;
        grid-template-columns: 80% 20%;
        grid-template-rows: 10% 40% 50%;
    }

    .blogpost-area span {
        align-self: end;
    }
}
@media screen and (max-width: 750px) {

    .blog-list {
        padding-left: 5vw;
        padding-right: 5vw;
    }
}

.blogpost-area .date {
    grid-row-start: 1;
}
.blogpost-area .title {
    grid-column: 1;
}