.videos-area {
    display: flex;
    flex-direction: column;
    background-color: white;
}
.videos-area h1 {
    padding-left: 1rem;
    margin-bottom: 0;
}

.videos-area span {
    margin-left: 1rem;
}
.videos-area iframe {
    margin-top: 1.5rem;;
}
@media screen and (min-width:900px) {
    .videos-area iframe {
        width: 700px;
        height: 400px;
        margin-left: 100px;
    }
}
@media screen and (max-width:900px) {
    .videos-area iframe {
        width:75vw;
        height: 40vw;   
        margin-left: 12vw;
    }
}