@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;600&display=swap');
.about-section {  
    display: flex;
    flex-direction: column;
    background-color: white;
    max-width: 100vw;
}
.about-section h1 {
    padding-left: 1rem;
    margin-bottom: 0;
}
.about-section span {
    padding-left: 1rem;
}
@media screen and (min-width: 900px) {
    .about-info {
        display: flex;
        justify-content: space-around;
        align-items: center;
        padding-top: 1rem;
    }
    .image-area img {
        border-radius: 50%;
        width: 20rem;
        height: 20rem;
    }
    .about-section p {
        border-left: 1px solid black;
    }   
    .self-contact-info {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }
    .self-contact-info img {
        max-width: 3rem;
        max-height: 3rem;
        margin-right: 2rem;
    }
}
@media screen and (max-width: 900px) {
    .about-info{
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    .image-area img {
        border-radius: 50%;
        max-width: 75vw;
        max-height: 75vw;
        padding: 1rem;
        padding-bottom: 0rem;
        
    }
    .image-area {
        padding: 0.5rem;
    }
    .about-section p {
        border-top: 1px solid black;
        max-width: 75vw;
        padding-bottom: 0rem;
    }
    .self-contact-info {
        display: flex;
    }
    .self-contact-info img {
        margin: 1rem;
        max-width: 10vw;
        max-height: 10vw;
    }
}
.image-area {
    margin-left: 2rem;
    margin-right: 2rem;
}
.resume-area {
    padding-top: 1rem;
    display: flex;
    justify-content: center;
}
.resume-area button {
    border: 1px solid black;
    border-radius: 10%;
    padding: 1rem;
    padding-left: 2rem;
    padding-right: 2rem;
    color: black;
    background-color: white;
    font-family: 'Montserrat', sans-serif;
    cursor: pointer;
}
.resume-area button:hover {
    background-color: lightgrey;
}