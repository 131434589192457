@import url(https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;600&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;600&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Open+Sans&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Open+Sans&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.nav-bar {
    background-color: white;
    z-index: 1;
    display: flex;
    justify-content: space-between;
    position: fixed;
    right:0;
    left: 0;
    box-shadow: 0 4px 14px 0 rgba(0, 0, 0, 0.15);
}
.nav-bar .nav-item {
    display: inline;
}
.nav-bar a {
    flex-grow: 1;
    flex-shrink: 0;
    padding: 1rem;
    color: black;
    text-align: center;
    text-decoration: none;
    cursor: pointer;
}
.nav-bar a:active {
    background-color: grey;
}
.nav-bar a:hover {
    background-color: lightgray;
}
.nav-bar button {
    font-family: inherit;
    border: none;
    background-color: transparent;
    flex-grow: 1;
    cursor: pointer;
}
.nav-bar button:focus {
    outline: none;
}
.nav-bar button:hover {
    background-color: lightgray;
}

@media screen and (max-width:850px)
{
    .desktop {
        display: none;
    }
    .nav-bar a {
        text-align: left;
    }
    .mobile {
        content: "";
        flex-grow: 10;
    }
}

.hero-area {
    display: flex;
}

.hero-image {
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    background-image: url("/assets/hero_imagecopy.jpg");
    background-size: cover;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-attachment: scroll;
    background-repeat: no-repeat;
    position: fixed;
    z-index: -1;
}

.hero-box {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    min-width: 95vw;
    text-align: left;
    justify-content: center;
}
.hero-box h1 {
    background-color: white;
    font-size: calc(3em + 0.2vw);
    display: inline-block;
    margin-right: 10vw;
    margin-top: 0;
    padding-left: 0;
    -webkit-box-decoration-break: slice;
            box-decoration-break: slice;
    -webkit-box-decoration-break: clone;
            box-decoration-break: clone;
}                                       
@media screen and (max-width:100vh) {
    .hero-image {
        background-position-x: 30%;        
    }
}
@media screen and (max-width:700px) {
    .hero-box h1 {
        font-size: calc(1em + 2.3vw);
        margin-right: 5vw;
        
    }
}
.text-line {
    display: flex;
}
.text-line .spacer {
    flex-grow: 1;
}
@supports (-webkit-overflow-scrolling: touch) {
    .hero-image {
        max-height: 85vh;
    }
    .hero-box {
        min-height: 85vh;
    }
}

.Typist .Cursor {
  display: inline-block; }
  .Typist .Cursor--blinking {
    opacity: 0.3;
    -webkit-animation: blink 0.8s infinite;
            animation: blink 0.8s infinite; }

@-webkit-keyframes blink {
  0% {
    -webkit-animation-timing-function: steps(1, end);
    animation-timing-function: steps(1, end);
    opacity: 1; }
  50% {
    -webkit-animation-timing-function: steps(1, end);
    animation-timing-function: steps(1, end);
    opacity: 0; }
  100% {

    opacity: 1; } }

@keyframes blink {
  0% {
    -webkit-animation-timing-function: steps(1, end);
    animation-timing-function: steps(1, end);
    opacity: 1; }
  50% {
    -webkit-animation-timing-function: steps(1, end);
    animation-timing-function: steps(1, end);
    opacity: 0; }
  100% {

    opacity: 1; } }
.about-section {  
    display: flex;
    flex-direction: column;
    background-color: white;
    max-width: 100vw;
}
.about-section h1 {
    padding-left: 1rem;
    margin-bottom: 0;
}
.about-section span {
    padding-left: 1rem;
}
@media screen and (min-width: 900px) {
    .about-info {
        display: flex;
        justify-content: space-around;
        align-items: center;
        padding-top: 1rem;
    }
    .image-area img {
        border-radius: 50%;
        width: 20rem;
        height: 20rem;
    }
    .about-section p {
        border-left: 1px solid black;
    }   
    .self-contact-info {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }
    .self-contact-info img {
        max-width: 3rem;
        max-height: 3rem;
        margin-right: 2rem;
    }
}
@media screen and (max-width: 900px) {
    .about-info{
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    .image-area img {
        border-radius: 50%;
        max-width: 75vw;
        max-height: 75vw;
        padding: 1rem;
        padding-bottom: 0rem;
        
    }
    .image-area {
        padding: 0.5rem;
    }
    .about-section p {
        border-top: 1px solid black;
        max-width: 75vw;
        padding-bottom: 0rem;
    }
    .self-contact-info {
        display: flex;
    }
    .self-contact-info img {
        margin: 1rem;
        max-width: 10vw;
        max-height: 10vw;
    }
}
.image-area {
    margin-left: 2rem;
    margin-right: 2rem;
}
.resume-area {
    padding-top: 1rem;
    display: flex;
    justify-content: center;
}
.resume-area button {
    border: 1px solid black;
    border-radius: 10%;
    padding: 1rem;
    padding-left: 2rem;
    padding-right: 2rem;
    color: black;
    background-color: white;
    font-family: 'Montserrat', sans-serif;
    cursor: pointer;
}
.resume-area button:hover {
    background-color: lightgrey;
}
.coding-area {
    background-color: white;
    display: flex;
    flex-direction: column;
}

.coding-area h1 {
     margin-bottom: 0;
     margin-left: 1rem;
 }

.coding-area span {
    margin-left: 1rem;
}
@media screen and (min-width: 900px) {
    .coding1 {
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .coding1 p {
        border-left: 1px solid black;
    }
    .coding1 img { 
        max-width: 15rem;
        max-height: 15rem;
        margin: 1rem;
        padding-left: 1vw;
    }
}
@media screen and (max-width: 900px) {

    .coding1 img { 
        max-width: 75vw;
        max-height: 75vw;
        padding: 1rem;
        border-bottom: 1px solid black;
    }
    .coding1 {
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    .coding1 p {
        max-width: 75vw;
    }
}
.music-section {
  display: flex;
  flex-direction: column;
  background-color: white;
  max-width: 100vw;
}

.music-section h1 {
  padding-left: 1rem;
  margin-bottom: 0;
}
.music-section span {
  margin-left: 1rem;
}
@media screen and (min-width: 900px) {
  .music-info {
    display: flex;
    justify-content: space-around;
    align-items: center;
    padding-top: 1rem;
  }
  .music-image-area img {
    border-radius: 50%;
    width: 20rem;
    height: 20rem;
  }
  .music-section p {
    margin: 1rem;
    border-left: 1px solid black;
  }
  .music-contact-info {
    display: flex;
    flex-direction: column-reverse;
    justify-content: space-between;
  }
  .music-contact-info img {
    max-width: 3rem;
    max-height: 3rem;
    margin-left: 2rem;
  }
}
@media screen and (max-width: 900px) {
  .music-info {
    display: flex;
    flex-direction: column-reverse;
    align-items: center;
  }
  .music-image-area img {
    border-radius: 50%;
    max-width: 75vw;
    max-height: 75vw;
    padding: 1rem;
    padding-bottom: 0;
  }
  .music-image-area {
    padding: 0.5rem;
  }
  .music-section p {
    border-top: 1px solid black;
    max-width: 75vw;
    padding-bottom: 0rem;
  }
  .music-contact-info {
    display: flex;
  }
  .music-contact-info img {
    margin: 1rem;
    max-width: 10vw;
    max-height: 10vw;
  }
}
.music-image-area {
  margin-left: 2rem;
  margin-right: 2rem;
}

.videos-area {
    display: flex;
    flex-direction: column;
    background-color: white;
}
.videos-area h1 {
    padding-left: 1rem;
    margin-bottom: 0;
}

.videos-area span {
    margin-left: 1rem;
}
.videos-area iframe {
    margin-top: 1.5rem;;
}
@media screen and (min-width:900px) {
    .videos-area iframe {
        width: 700px;
        height: 400px;
        margin-left: 100px;
    }
}
@media screen and (max-width:900px) {
    .videos-area iframe {
        width:75vw;
        height: 40vw;   
        margin-left: 12vw;
    }
}
.other-area {
    display: flex;
    flex-direction: column;
    background-color: white;
}

.other-area h1 {
    margin-left: 1rem;
    margin-bottom:0;
}

.other-area span {
    margin-left: 1rem;
}

@media screen and (min-width: 900px) {
    .other-list {
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .other-list p {
        border-left: 1px solid black;
    }
    .other-list img { 
        max-width: 15rem;
        max-height: 15rem;
        margin: 1rem;
        padding-left: 1vw;
    }
}
@media screen and (max-width: 900px) {
    .other-list img { 
        max-width: 75vw;
        max-height: 75vw;
        padding: 1rem;
        border-bottom: 1px solid black;
    }
    .other-list {
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    .other-list p {
        max-width: 75vw;
    }
}
.main-page {
  display: flex;
  flex-direction: column;
  font-family: 'Montserrat', sans-serif;
  justify-content: flex-start;
  font-size: calc(1em + 0.2vw);
}
img {
    overflow: hidden;
}
p {

    flex: 1 1;
    justify-content: left;
    text-align: left;
    padding: 15px;
}
@media screen and (min-width:700px) {
  .main-body {
    display: flex;
  }
  .main-body .spacer {
    background-color: white;
    content: "";
    flex-grow: 1;
  }
  .main-content {
    max-width: 900px;
  }
}

.menu {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: fixed;
    background-color: white;
    min-width: 100vw;
    min-height: 120vh;
    transition: 0.8s ease; 
    z-index: 1;
}
.menu a {
    padding: 3vh;
    text-decoration: none;
    color: inherit;
    font-size: calc(1rem + 0.5vh + 1vw);
    cursor: pointer;
}
.menu a:hover {
    text-decoration: underline;
}
.menu .spacer {
    min-height: 20vh;
}
/* .menu .blog-button {
    background-color: transparent;
    border: none;
    text-decoration: none;
    font: inherit;
    color: inherit;
    padding: 3vh;
} */
@media screen and (min-width:850px)
{
    .sidebar-button {
        display: none;
    }
}
.sidebar-button {
    display: static;
    position: fixed;
    right:0;
    background-color: transparent;
    border: none;
    padding: 1rem;
    z-index: 2;
    cursor: pointer;
}
.sidebar-button:focus{
    outline: none;
}

.sidebar img {
    border-radius: 0;
    max-height: 1.2rem;
    min-width: 1.5rem;
}
.footer-body {
    padding-top: 10vh;
    background-color: white	;
}
.footer-body p {
    margin-top: 0;
    margin-bottom: 0;
    color: black;
    font-size: 0.8rem;
    padding-bottom: 0.5vh;
    padding-top: 0;
}
.footer-body a {
    text-decoration: none;
    color: inherit;
}
.blog {
    font-family: 'Open Sans', sans-serif;
}
.blog-nav {
    background-color: #f5f3f4;
    z-index: 1;
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: fixed;
    right:0;
    left: 0;
    top: 0;
    box-shadow: 0px 2px 10px 4px lightgray;
    padding-left: 1rem;
    padding-right: 1rem;
}
.blog-nav a {
    color: black;
    text-decoration: none;
    text-decoration: initial;
}
.blog-nav img {
    max-width: 2rem;
}
.blog .blog-list {
    display: flex;
    flex-direction: column;
    padding-top: 6rem;
    max-width: 700px;
    margin: auto;
}
.blog-list a {
    color: black;
    text-decoration: none;
    text-decoration: initial;
}
.blog-list a h1 {
    margin-bottom: 0.2rem;
}
.blog-list a:hover h1 {
    text-decoration: underline;
}
.blogpost-area {
    padding: 1rem;
    border-bottom: 1px solid black;
}
.blogpost-area p {
    padding-right: 0;
    padding-left: 0;
}
@media screen and (min-width:750px) {
    .blogpost-area {
        display: grid;
        grid-template-columns: 80% 20%;
        grid-template-rows: 10% 40% 50%;
    }

    .blogpost-area span {
        align-self: end;
    }
}
@media screen and (max-width: 750px) {

    .blog-list {
        padding-left: 5vw;
        padding-right: 5vw;
    }
}

.blogpost-area .date {
    grid-row-start: 1;
}
.blogpost-area .title {
    grid-column: 1;
}
.blog-page{
    font-family: 'Open Sans', sans-serif;
}
article {
    padding-top: 6rem;
}
article p {
    padding: 0;
    line-height: 1.5;
} 
.italics {
    font-style: italic;
}
article h2, h3, h4 {
    padding-top: 100px;
    margin-top: -60px; 
    margin-bottom: 0px;
    /* this is so anchor links aren't covered by the navbar */
}

.content-area a {
    color: #000;
    text-decoration: none;
    background-color: #E4F1E6;
}
.content-area a:hover {
    background-color: #CAE3CD;
}

blockquote {
    border-left: 10px solid #E4F1E6;
    padding-left: 20px;
    align-self: left;
    font-style: italic;
}

article .spacer {
    min-height: 10rem;
}
article .subtly-bigger {
    font-size: 1.33rem;
}
article .tag {
    font-size: 0.9rem;
    border-bottom: 1px solid black;
}

.table-of-contents {
    font-size: 16px;
    font-family: 'Open Sans', sans-serif;
    width: 80vw;
    background-color: white;
    box-shadow: 0px 0px 10px lightgray;
    padding: 30px;
    border-radius: 30px;
    list-style-type: none;
}

.table-of-contents li {
        padding: 3px 0 3px 0;
}

@media screen and (min-width:1000px) {
    .content-area {
        width: 100vw; 
        float: right; 
        position: relative; 
        display: flex;
        justify-content: center;
    }
    article {
        margin-right: 250px;
        width: 700px;
        display: flex;
        flex-direction: column;
    }

    article img {
        object-fit: cover;
        width: 100%;
        max-height: 100%;
    }
    article .small {
        width: 600px;
        padding-left: 50px;
        justify-self: center;
    }

    article {
        font-size: 1.2rem;
    }
    .positioner {
        position: absolute;
    }
    .fixed {
        width: 250px; 
        height: 100px; 
        position: fixed; 
        left: calc(50vw + 250px);
        top: 80px; 
    }
    .table-of-contents {
        font-size: 13px;
        width: 200px;
    }


}

@media screen and (max-width:1000px) {
    article {
        display: flex;
        flex-direction: column;
        margin-left: 5vw;
        margin-right: 5vw;
        font-size: 1.1rem;;
        align-content: center;
    }
    article img {
        width: 100%;
        height: auto;
    }
}
