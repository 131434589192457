.footer-body {
    padding-top: 10vh;
    background-color: white	;
}
.footer-body p {
    margin-top: 0;
    margin-bottom: 0;
    color: black;
    font-size: 0.8rem;
    padding-bottom: 0.5vh;
    padding-top: 0;
}
.footer-body a {
    text-decoration: none;
    color: inherit;
}