@import url('https://fonts.googleapis.com/css2?family=Open+Sans&display=swap');
.blog-page{
    font-family: 'Open Sans', sans-serif;
}
article {
    padding-top: 6rem;
}
article p {
    padding: 0;
    line-height: 1.5;
} 
.italics {
    font-style: italic;
}
article h2, h3, h4 {
    padding-top: 100px;
    margin-top: -60px; 
    margin-bottom: 0px;
    /* this is so anchor links aren't covered by the navbar */
}

.content-area a {
    color: #000;
    text-decoration: none;
    background-color: #E4F1E6;
}
.content-area a:hover {
    background-color: #CAE3CD;
}

blockquote {
    border-left: 10px solid #E4F1E6;
    padding-left: 20px;
    align-self: left;
    font-style: italic;
}

article .spacer {
    min-height: 10rem;
}
article .subtly-bigger {
    font-size: 1.33rem;
}
article .tag {
    font-size: 0.9rem;
    border-bottom: 1px solid black;
}

.table-of-contents {
    font-size: 16px;
    font-family: 'Open Sans', sans-serif;
    width: 80vw;
    background-color: white;
    box-shadow: 0px 0px 10px lightgray;
    padding: 30px;
    border-radius: 30px;
    list-style-type: none;
}

.table-of-contents li {
        padding: 3px 0 3px 0;
}

@media screen and (min-width:1000px) {
    .content-area {
        width: 100vw; 
        float: right; 
        position: relative; 
        display: flex;
        justify-content: center;
    }
    article {
        margin-right: 250px;
        width: 700px;
        display: flex;
        flex-direction: column;
    }

    article img {
        object-fit: cover;
        width: 100%;
        max-height: 100%;
    }
    article .small {
        width: 600px;
        padding-left: 50px;
        justify-self: center;
    }

    article {
        font-size: 1.2rem;
    }
    .positioner {
        position: absolute;
    }
    .fixed {
        width: 250px; 
        height: 100px; 
        position: fixed; 
        left: calc(50vw + 250px);
        top: 80px; 
    }
    .table-of-contents {
        font-size: 13px;
        width: 200px;
    }


}

@media screen and (max-width:1000px) {
    article {
        display: flex;
        flex-direction: column;
        margin-left: 5vw;
        margin-right: 5vw;
        font-size: 1.1rem;;
        align-content: center;
    }
    article img {
        width: 100%;
        height: auto;
    }
}