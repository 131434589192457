.coding-area {
    background-color: white;
    display: flex;
    flex-direction: column;
}

.coding-area h1 {
     margin-bottom: 0;
     margin-left: 1rem;
 }

.coding-area span {
    margin-left: 1rem;
}
@media screen and (min-width: 900px) {
    .coding1 {
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .coding1 p {
        border-left: 1px solid black;
    }
    .coding1 img { 
        max-width: 15rem;
        max-height: 15rem;
        margin: 1rem;
        padding-left: 1vw;
    }
}
@media screen and (max-width: 900px) {

    .coding1 img { 
        max-width: 75vw;
        max-height: 75vw;
        padding: 1rem;
        border-bottom: 1px solid black;
    }
    .coding1 {
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    .coding1 p {
        max-width: 75vw;
    }
}