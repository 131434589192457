.nav-bar {
    background-color: white;
    z-index: 1;
    display: flex;
    justify-content: space-between;
    position: fixed;
    right:0;
    left: 0;
    box-shadow: 0 4px 14px 0 rgba(0, 0, 0, 0.15);
}
.nav-bar .nav-item {
    display: inline;
}
.nav-bar a {
    flex-grow: 1;
    flex-shrink: 0;
    padding: 1rem;
    color: black;
    text-align: center;
    text-decoration: none;
    cursor: pointer;
}
.nav-bar a:active {
    background-color: grey;
}
.nav-bar a:hover {
    background-color: lightgray;
}
.nav-bar button {
    font-family: inherit;
    border: none;
    background-color: transparent;
    flex-grow: 1;
    cursor: pointer;
}
.nav-bar button:focus {
    outline: none;
}
.nav-bar button:hover {
    background-color: lightgray;
}

@media screen and (max-width:850px)
{
    .desktop {
        display: none;
    }
    .nav-bar a {
        text-align: left;
    }
    .mobile {
        content: "";
        flex-grow: 10;
    }
}
