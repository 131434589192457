.other-area {
    display: flex;
    flex-direction: column;
    background-color: white;
}

.other-area h1 {
    margin-left: 1rem;
    margin-bottom:0;
}

.other-area span {
    margin-left: 1rem;
}

@media screen and (min-width: 900px) {
    .other-list {
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .other-list p {
        border-left: 1px solid black;
    }
    .other-list img { 
        max-width: 15rem;
        max-height: 15rem;
        margin: 1rem;
        padding-left: 1vw;
    }
}
@media screen and (max-width: 900px) {
    .other-list img { 
        max-width: 75vw;
        max-height: 75vw;
        padding: 1rem;
        border-bottom: 1px solid black;
    }
    .other-list {
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    .other-list p {
        max-width: 75vw;
    }
}