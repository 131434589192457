@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;600&display=swap');

.main-page {
  display: flex;
  flex-direction: column;
  font-family: 'Montserrat', sans-serif;
  justify-content: flex-start;
  font-size: calc(1em + 0.2vw);
}
img {
    overflow: hidden;
}
p {

    flex: 1 1 0px;
    justify-content: left;
    text-align: left;
    padding: 15px;
}
@media screen and (min-width:700px) {
  .main-body {
    display: flex;
  }
  .main-body .spacer {
    background-color: white;
    content: "";
    flex-grow: 1;
  }
  .main-content {
    max-width: 900px;
  }
}
